import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import Img from "gatsby-image"
import { FaBars } from "react-icons/fa"



const Header = ({data}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { site } = useStaticQuery(graphql`
  {
    site {
          data: siteMetadata {
            menu {
              name
              to
            }
          }
        }
        icon: file(relativePath: {eq: "icon.png"}) {
          childImageSharp {
            fluid {
              base64
              tracedSVG
              srcWebp
              srcSetWebp
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      
  }
  `)
  console.log(site);

  return (
    <div className="container pb-4 pt-8 md:pt-12 items-center">
      <div className="flex justify-between">
        
        <Link to="/">
            {/* <svg height="35" width="100%" class="text">
              <defs>
                <linearGradient id="geekColor" gradientTransform="rotate(90)">
                  <stop offset="0%" stop-color="#1f005c">
                    <animate
                      attributeName="stop-color"
                      values="#ca485c;#e16b5c;#f39060"
                      dur="5s"
                      begin=".5s"
                      repeatCount="indefinite"
                    ></animate>
                  </stop>

                  <stop offset="100%" stop-color="#f39060">
                    <animate
                      attributeName="stop-color"
                      values="#ca485c;#e16b5c;#f39060"
                      dur="5s"
                      begin=".5s"
                      repeatCount="indefinite"
                    ></animate>
                  </stop>
                </linearGradient>
              </defs>
              <text class="logo_text" y="25">
                Corey Kellgren
              </text>
            </svg> */}
          {/* <StaticImage className="w-12 float-left" src="https://i.ibb.co/sywbwL7/ckavatar.png" style={{marginTop:"-10px"}}/> */}
          {/* <StaticImage placeholder="blurred" style={{width: "500px", marginRight:"5px"}} alt={"Megan Bubley Headshot"} src={"https://i.ibb.co/GPJt1q3/meg-avatar.png"} /> */}
          {/* <Img
              fluid={icon}
              alt="John Doe"
              className="rounded-md shadow-md"
            /> */}
          <img className="w-10 float-left" src="https://i.ibb.co/2c9kB9L/ckavatar.png" style={{marginTop:"-10px"}}/>
          <h1 className="ml-2 text-md text-gray-500 float-left">Corey Kellgren</h1>
          {/* <img alt="Logo" style={{marginLeft:"-35px",marginTop:"-25px", width:"20rem"}} className="float-left" src="logo.svg" /> */}
        </Link>

        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto text-gray-400 -mt-1" />
        </button>

        <div className="hidden sm:block">
          {site.data.menu.map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-gray-500 hover:text-gray-200 hover:border-gray-200 transition duration-150 ease-in-out"
              activeClassName="border-gray-300 text-gray-100 hover:border-blue-100"
              to={link.to}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </div>
  )
}

export default Header